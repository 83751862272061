<template>
  <BannerOtherPages title="Our Goal" />
  <div class="container cont-wraper">
    <div class="page-title">
      <h2>Our Commitment to Excellence</h2>
      <p class="lead">
        At Lifelayer Health Solutions Pvt. Ltd., we aim to revolutionize healthcare through cutting-edge technology and innovation.
      </p>
    </div>

    <!-- Mission Section -->
    <section class="mb-5">
      <div class="card shadow-sm p-4">
        <h3 class="card-title">Our Mission</h3>
        <p class="card-text">
          To deliver comprehensive healthcare IT solutions that empower healthcare providers to deliver better patient outcomes efficiently and effectively.
        </p>
      </div>
    </section>

    <!-- Vision Section -->
    <section class="mb-5">
      <div class="card shadow-sm p-4">
        <h3 class="card-title">Our Vision</h3>
        <p class="card-text">
          To be a global leader in healthcare technology by providing innovative, scalable, and user-friendly solutions that transform healthcare delivery.
        </p>
      </div>
    </section>

    <!-- Core Values Section -->
    <section>
      <div class="card shadow-sm p-4">
        <h3 class="card-title">Our Core Values</h3>
        <ul class="card-text">
          <li><strong>Innovation:</strong> Driving change through innovative solutions.</li>
          <li><strong>Integrity:</strong> Upholding the highest standards of ethics and transparency.</li>
          <li><strong>Excellence:</strong> Striving for excellence in every aspect of our work.</li>
          <li><strong>Collaboration:</strong> Building strong partnerships with healthcare providers and stakeholders.</li>
          <li><strong>Customer Focus:</strong> Prioritizing the needs and well-being of our customers.</li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import BannerOtherPages from "@/components/BannerOtherPages.vue";

export default {
  components: {
    BannerOtherPages,
  },
};
</script>

<style scoped>
.banner_content {
  background-image: url(https://cdn.pixabay.com/photo/2020/04/09/10/15/desk-5020800_960_720.jpg);
  position: relative;
  height: 450px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: grid;
  place-items: center;
  place-content: center;
}

h2, h3 {
  font-weight: bold;
}

.lead {
  font-size: 1.25rem;
  color: #555;
  margin-bottom: 1.5rem;
}

.card {
  border-radius: 12px;
  background-color: #f8f9fa;
  border: none;
}

.card-title {
  font-size: 1.75rem;
  color: #007bff;
  margin-bottom: 0.5rem;
}

.card-text {
  font-size: 1rem;
  color: #343a40;
  line-height: 1.6;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
  font-size: 1rem;
}
</style>