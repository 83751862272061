<template>
     <BannerOtherPages title="Social Media Platform" />
     <div class="container cont-wraper">
     <div class="page-title" style="width: 100%; "><h2>Connect, share, and discover with your community</h2></div>
     <div class="container my-5">
    <!-- Features Section -->
    <section class="mb-5">
      <h2 class="text-center mb-4">Platform Features</h2>
      <div class="row">
        <div class="col-md-4 mb-4" v-for="(feature, index) in features" :key="index">
          <div class="card h-100 shadow-sm">
            <div class="card-body text-center">
              <div class="mb-3">
                <i :class="feature.icon" class="display-4 text-primary"></i>
              </div>
              <h5 class="card-title">{{ feature.title }}</h5>
              <p class="card-text">{{ feature.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- User Engagement Section -->
    <section class="bg-light py-5">
      <h2 class="text-center mb-4">Join the Community</h2>
      <div class="text-center">
        <p>Connect with like-minded individuals, share ideas, and stay informed.</p>
        <button class="btn btn-primary btn-lg">Sign Up Now</button>
      </div>
    </section>

<section>

<h2 class="mb-4">Why Choose Us?</h2>
<div class ="msg_chat" style="display: grid; grid-template-columns: 1fr 1fr;">
<div>
<p>A Platform Built with You in Mind</p>
<li><span style="font-weight: 700;">User-Friendly Experience:</span> Navigate a sleek and intuitive interface</li>
<li><span style="font-weight: 700;">Enhanced Security: </span>Enjoy peace of mind with cutting-edge data protection technologies.
</li>
<li><span style="font-weight: 700;">Engagement Opportunities: </span>Stay inspired by engaging content and vibrant discussions</li>
</div>

<div class="img_chat">
<img src="../assets/img/gif/chat_msg.gif" height="300px" width="300px" style="position:relative;margin-top: -100px;">
</div>
</div>
</section>
    <!-- Testimonials Section -->
    <section class="mt-5">
      <h2 class="text-center mb-4">What Users Are Saying</h2>
      <div class="row">
        <div class="col-md-6 mb-4" v-for="(testimonial, index) in testimonials" :key="index">
          <div class="card h-100 shadow-sm">
            <div class="card-body">
              <p class="card-text">"{{ testimonial.feedback }}"</p>
              <div class="mt-3">
                <h6 class="mb-0">{{ testimonial.name }}</h6>
                <small class="text-muted">{{ testimonial.role }}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
     </div>
</template>
<script>
import BannerOtherPages from '@/components/BannerOtherPages.vue';

export default {
    components: {
        BannerOtherPages
    },
    data() {
    return {
     
   features: [
        {
          icon: "bi bi-people",
          title: "Social Connections",
          description: "Build connections and expand your network effortlessly.",
        },
        {
          icon: "bi bi-chat-dots",
          title: "Instant Messaging",
          description: "Communicate instantly with your friends and colleagues.",
        },
        {
          icon: "bi bi-shield-lock",
          title: "Privacy Focused",
          description: "Your data is secure with industry-leading protection.",
        },
      ],
      testimonials: [
        {
          feedback: "This platform has transformed how I connect with others.",
          name: "John Doe",
          role: "Entrepreneur",
        },
        {
          feedback: "Amazing features and a great user experience.",
          name: "Jane Smith",
          role: "Software Engineer",
        },
      ],
    };
  },
  methods: {
    addPost() {
      this.posts.unshift({
        id: this.posts.length + 1,
        user: "You",
        content: this.newPost,
        time: "Just now",
        avatar: "https://via.placeholder.com/50",
      });
      this.newPost = "";
    },
  },
}

</script>

<style scoped>

.banner_content{
background:url(https://cdn.pixabay.com/photo/2018/05/16/18/03/social-media-3406607_1280.jpg);
position: relative;
height: 450px;
width: 100%;
background-size: cover;
background-repeat: no-repeat;
background-position: top;
display: grid;
place-items: center;
place-content: center;
}

card {
  border-radius: 0.5rem;
}
.card-body i {
  font-size: 2.5rem;
}

@media(max-width:768px){
  .msg_chat{
    display: grid;
    grid-template-columns: 100% !important;
  }
  .img_chat{
    position: relative;
    margin: 100px 0px !important;
  }
}
</style>