<template>
  
     
    <div class="container mt-5">
      <div class="row ">
        <div class="col-md-10">
          

          <div class="row ">
            <div class="col-md-6">
              
              <h2 id="about_us">
                Let's talk about everything!

          </h2><hr>
              <p style="width: 100%;" class="contact_para">Reach out to us anytime through our contact form, email, or phone. Let’s work together to create innovative solutions that meet your needs. We look forward to hearing from you!</p>

              <p><img src="../assets/img/gif/phone-contact.gif" alt="Image" class="img-fluid"></p>
            </div>
            <div class="col-md-6">
              
              <form @submit.prevent="submitForm" class="mb-5" method="post" id="contactForm" name="contactForm">
                <div class="row">
                  <div class="col-md-12 form-group">
                    <input type="text" class="form-control" name="name" id="name" v-model="formData.name" placeholder="Your name">
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 form-group">
                    <input type="text" class="form-control" name="email" id="email" v-model="formData.email" placeholder="Email">
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 form-group">
                    <input type="text" class="form-control" name="number" id="number" v-model="formData.number" placeholder="Number">
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 form-group">
                    <input type="text" class="form-control" name="subject" id="subject" v-model="formData.subject" placeholder="Subject">
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 form-group">
                    <textarea class="form-control" name="message" id="message" v-model="formData.message" cols="30" rows="7" placeholder="Write your message"></textarea>
                  </div>
                </div>  
                <div class="row">
                  <div class="col-12">
                    <input type="submit" value="Send Message" class="btn btn-primary rounded-0 py-2 px-4">
                  <span class="submitting"></span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

</template>

<script>
  import { toast } from 'vue3-toastify';
  import 'vue3-toastify/dist/index.css';
import axios from 'axios';
export default {
    components: {
    
    },

    data(){
        return{
            formData: {
                name: '',
                email: '',
                subject: '',
                message: '',
                number: '',
            },
            formMessage: {
                success: '',
                warning: ''
            }
        }
    },
    methods: {
        async submitForm() {
            try {
               await axios.post('http://localhost:5000/contact/postQuery', this.formData);
                this.formMessage.success = 'Your message was sent, thank you!';
                toast.success("Your query have been sent. Our team will reach you soon..!!", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
        });
                this.formMessage.warning = '';
                this.clearForm();
            } catch (error) {
                toast.error("Failed To Send Your Message. Please try again later", {
                position: "bottom-right",
                width: "400px",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
        });
                this.formMessage.warning = 'Failed to send your message. Please try again.';
                this.formMessage.success = '';
            }
        },
        clearForm() {
            this.formData.name = '';
            this.formData.email = '';
            this.formData.subject = '';
            this.formData.message = '';
            this.formData.number = '';
        }
    }
}
</script>

<style scoped>

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Roboto", sans-serif;
  color: #000; }

a {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease; }
  a, a:hover {
    text-decoration: none !important; }

.text-black {
  color: #000; }



.heading {
  font-size: 2.5rem;
  font-weight: 900; }

.form-control {
  border: none;
  background: #f3f3f3;
  margin: 13px 0px;
 }
  .form-control:active, .form-control:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #000;
    background: #f3f3f3; }

.col-form-label {
  color: #000; }

.btn, .form-control, .custom-select {
  height: 50px; }

.custom-select:active, .custom-select:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #000; }

.btn {
  border: none;
  border-radius: 4px !important; }
  .btn.btn-primary {
    background: #000;
    color: #fff;
    padding: 15px 20px; }
  .btn:hover {
    color: #fff; }
  .btn:active, .btn:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none; }

.box {
  padding: 40px;
  background: #fff;
  -webkit-box-shadow: -30px 30px 0px 0 rgba(0, 0, 0, 0.08);
  box-shadow: -30px 30px 0px 0 rgba(0, 0, 0, 0.08); }
  .box h3 {
    font-size: 14px;
    margin-bottom: 30px;
    text-align: center; }

label.error {
  font-size: 12px;
  color: red; }

#message {
  resize: vertical; }

#form-message-warning, #form-message-success {
  display: none; }

#form-message-warning {
  color: #B90B0B; }

#form-message-success {
  color: #55A44E;
  font-size: 18px;
  font-weight: bold; }

.submitting {
  float: left;
  width: 100%;
  padding: 10px 0;
  display: none;
  font-weight: bold;
  font-size: 12px;
  color: #000; }

  .img-fluid{
    width: 300px;
    position: relative;
    top: -20px;
  }


</style>