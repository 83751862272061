<template>
    <BannerOtherPages title="Job Board" />
    <div class="container cont-wraper">
    <div class="page-title" style="margin-top: 10px;">
      <h2>Discover exciting career opportunities tailored for you.</h2>
    </div>
  </div>
   <!-- Job Listings Section -->
   <section class="container py-5">
      <h2 class="text-center mb-4">Available Jobs</h2>
      <div class="row g-4">
        <div class="col-md-4" v-for="job in jobs" :key="job.id">
          <div class="card h-100 shadow-sm">
            <div class="card-body">
              <h5 class="card-title text-primary">{{ job.title }}</h5>
              <h6 class="card-subtitle mb-2 text-muted">{{ job.company }}</h6>
              <p class="card-text text-muted">
                <i class="bi bi-geo-alt-fill"></i> {{ job.location }}
              </p>
              <p class="card-text">{{ job.description }}</p>
              <button class="btn btn-outline-primary w-100">Apply Now</button>
            </div>
          </div>
        </div>
        <p v-if="jobs.length === 0" class="text-center">
          No jobs are currently available. Please check back later!
        </p>
      </div>
    </section>

</template>

<script>

import BannerOtherPages from '@/components/BannerOtherPages.vue';

export default {
  components: {
      BannerOtherPages
  },
  data(){
    return{
        jobs: [
        {
          id: 1,
          title: "Frontend Developer",
          company: "Tech Solutions Inc.",
          location: "New York, NY",
          description: "Build modern web applications using Vue.js and React.",
        },
        {
          id: 2,
          title: "Backend Developer",
          company: "DataHub",
          location: "San Francisco, CA",
          description: "Develop robust APIs and server-side logic.",
        },
        {
          id: 3,
          title: "UI/UX Designer",
          company: "Creative Minds",
          location: "Remote",
          description: "Design user-friendly interfaces and improve user experiences.",
        },
        {
          id: 4,
          title: "Project Manager",
          company: "Innovative Co.",
          location: "Los Angeles, CA",
          description: "Manage diverse teams and deliver projects on time.",
        },
        {
          id: 5,
          title: "Data Scientist",
          company: "AnalyticsPro",
          location: "Chicago, IL",
          description: "Analyze data to provide actionable insights.",
        },
      ],
    }
  }
}

</script>

<style scoped>

.banner_content{
background:url(https://cdn.pixabay.com/photo/2024/07/18/21/29/darts-8905121_960_720.jpg);
position: relative;
height: 450px;
width: 100%;
background-size: cover;
background-repeat: no-repeat;
background-position: top;
display: grid;
place-items: center;
place-content: center;
}

</style>