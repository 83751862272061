
<template>
    
    <BannerOtherPages title=" Business Philosophy" />
    <div class="container-fluid cont-wraper">
    <div class="container">
      <div class="site-contant mt-5">
        <div class="content">
            <div class="page-title"><h2 class="how_we_can">HOW WE CAN</h2></div>
             <p>Writing about a company’s business philosophy is very complicated. We are not hiding anything from our client but YES hiding their business secrecy, as LifeLayer knows which is very much important from all aspects.</p>
             <p>Because LifeLayer is in <b>Core IT</b> and <b>Healthcare IT</b> business, we are trying to achieve the goal in between timeline frame. LifeLayer doesn't manufacture or rebuild not does LifeLayer sell any equipment. LifeLayer is always wants to become your product service and support partner, never your service competitor and never takes control of your customer.</p>
             <p>We are believing that your data on your own server is safe from others and you knows how to handle, LifeLayer can guide you ‘how’. LifeLayer doesn't belief on third party free plugins. LifeLayer was built on the foundation of serving clients and we have effectively supported our clients for more than 13<sup>+</sup> years. It doesn’t matter whether you are hiring a developer, a designer or a marketing personals – we believe, they have to be honest to serve you.</p>
             <p><strong>Some of the specific reasons for our success are outlined in the following LifeLayer partnership characteristics -</strong> 
            </p>
            <ul>
            <li><b>Strategy</b> – LifeLayer works with our clients however/wherever asked, making us a key part of each partner’s service delivery strategy.</li>
            <li><b>Flexibility</b> – Whether by geographic coverage area, modality, model, service activity, or some combination thereof, LifeLayer is flexible and fits within our clients’ existing service processes.</li>
            <li><b>Scalable</b> – We do not require contract minimums; LifeLayer always fits the scale and scope of each client’s need.</li>
            <li><b>Dependability</b> – time-tested and reliable, our clients and their end users depend on LifeLayer every day.</li>
            <li><b>Professionalism</b> – Service is LifeLayer's only business and our purpose is to provide our clients professional, dependable service everywhere.</li>
            <li><b>Engaged</b> – LifeLayer's entire focus is our clients; demonstrated through our engaged customer relationships and detailed attention to each client’s needs.</li>
            <li><b>Responsiveness/Ease of Use</b> – LifeLayer's clients tell us we are the easiest partner they work with, in fact, no other company even comes close to our responsiveness.</li>
            <li><b>Integrity</b> – LifeLayer's word is its bond, and when LifeLayer makes a mistake, we take responsibility for that mistake and fix it. Our organization embodies the service and integrity of a local, small business while being supported by the resources of a national company.</li>
            </ul>
        </div>
      </div>
    </div>
  </div>
</template>

    

<script>
import BannerOtherPages from '@/components/BannerOtherPages.vue';

export default {
    components: {
        BannerOtherPages
    }
}
</script>
<style scoped>
.banner_content{
/* background:url(../assets/img/carrer-img.jpg); */
background: url(https://cdn.pixabay.com/photo/2024/06/14/06/27/office-8828993_1280.jpg);
position: relative;
height: 450px;
width: 100%;
background-size: cover;
background-repeat: no-repeat;
background-position: top;
display: grid;
place-items: center;
place-content: center;
}
.how_we_can {
  margin-top: -36px !important;
}
</style>