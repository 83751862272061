<template>
  <BannerOtherPages title="Career" />
  <div class="container mt-5">
    <div class="page-title mt-5">
      <h2>Track your health, stay fit, and achieve your goals!</h2>
    </div>
  </div>

  <!-- Career Opportunities Section -->
  <section class="container py-5">
    <h2 class="text-center mb-4">Current Job Openings</h2>
    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4">
      <div v-for="job in jobs" :key="job.id" class="col">
        <div class="card h-100 shadow-sm">
          <div class="card-body">
            <h5 class="card-title">{{ job.title }}</h5>
            <h6 class="card-subtitle mb-2 text-muted">{{ job.department }}</h6>
            <p class="card-text">{{ job.description }}</p>
            <p><strong>Location:</strong> {{ job.location }}</p>
            <p><strong>Experience:</strong> {{ job.experience }}</p>
            <button class="btn btn-primary w-100" @click="apply(job.id)">
              Apply Now
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- About Company Section -->
  <section class="bg-light py-5">
    <div class="container">
      <h2 class="text-center mb-4">About Us</h2>
      <p class="text-center">
        We are a dynamic and innovative company focused on creating a positive impact. Join us and be part of an exciting journey to shape the future.
      </p>
    </div>
  </section>

  <!-- Job Application Form -->
  <section class="container py-5">
    <h2 class="text-center mb-4">Apply for a Job</h2>
    <form @submit.prevent="submitApplication" class="form-container mx-auto">
      <div class="mb-3">
        <label for="name" class="form-label">Full Name</label>
        <input type="text" class="form-control" id="name" v-model="applicant.name" required />
      </div>
      <div class="mb-3">
        <label for="email" class="form-label">Email Address</label>
        <input type="email" class="form-control" id="email" v-model="applicant.email" required />
      </div>
      <div class="mb-3">
        <label for="resume" class="form-label">Upload Resume</label>
        <input type="file" class="form-control" id="resume" @change="handleFileUpload" required />
      </div>
      <div class="mb-3">
        <button type="submit" class="btn btn-success w-100">Submit Application</button>
      </div>
    </form>
  </section>
</template>

<script>
import BannerOtherPages from "@/components/BannerOtherPages.vue";

export default {
  components: {
    BannerOtherPages,
  },
  data() {
    return {
      jobs: [
        {
          id: 1,
          title: "Software Engineer",
          department: "Engineering",
          description: "Develop and maintain our core software systems.",
          location: "New York, NY",
          experience: "3+ years",
        },
        {
          id: 2,
          title: "Marketing Manager",
          department: "Marketing",
          description: "Plan and execute marketing campaigns to boost company growth.",
          location: "Remote",
          experience: "5+ years",
        },
        {
          id: 3,
          title: "Product Designer",
          department: "Design",
          description: "Create user-friendly designs for our products.",
          location: "San Francisco, CA",
          experience: "4+ years",
        },
      ],
      applicant: {
        name: "",
        email: "",
        resume: null,
      },
    };
  },
  methods: {
    apply(jobId) {
      alert(`You have applied for job ID: ${jobId}`);
    },
    handleFileUpload(event) {
      this.applicant.resume = event.target.files[0];
    },
    submitApplication() {
      if (this.applicant.name && this.applicant.email && this.applicant.resume) {
        alert("Application Submitted Successfully!");
        this.applicant.name = "";
        this.applicant.email = "";
        this.applicant.resume = null;
      } else {
        alert("Please fill all fields and upload your resume.");
      }
    },
  },
};
</script>

<style scoped>
.card {
  transition: transform 0.2s ease-in-out;
}
.card:hover {
  transform: translateY(-5px);
}
.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.btn-primary {
  margin-top: auto;
}
.banner_content {
  background: url(../assets/img/lifelayer10-img.jpg);
  position: relative;
  height: 450px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  display: grid;
  place-items: center;
  place-content: center;
}
.form-container {
  max-width: 500px;
  width: 100%;
}
</style>
