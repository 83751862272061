<template>

<BannerOtherPages title="About Us" />
<div class="container-fluid cont-wraper">
        <div class="container">
            <div class="site-contant">
                <div class="content">
                    <div class="page-title">
                        <h2>WHO WE ARE</h2>
                    </div>
                    <p>Let’s start to focusing on 22<sup>nd</sup> Century and ready to start work on <b>ERP</b> with
                        <b>Big Data</b>.
                    </p>
                    <p>Some Non-IT professionals are thinking that, “IT is a wastage of money”, DON’T, without IT you
                        can’t grow and be ready to listen your IT requirements and if you want to grow - don’t ignore.
                        Remember a Sales Personal can only sale his/her product, but Support Personal can handle sales
                        because he/she knows how to achieve their business goal with correct requirements.</p>
                    <p>As LifeLayer is running by Medical Doctor and IT Engineer, a Doctor knows how to treat patient
                        and what patient is expecting from Doctor and IT engineer knows how to get resolution for your
                        IT problems.</p>
                    <p>LifeLayer is working for both industries <b>CORE IT</b> and <b>HEALTHCARE-IT</b>. Day-by-day IT
                        (Information Technology) and Healthcare-IT is growing and everybody is expecting something
                        bigger from it, may be from Web or from ERP. We are from last 15 years serving to both
                        industries (<b>Core-IT</b> and <b>Healthcare-IT</b>) and knows how to inject power to boost your
                        working environment.</p>
                    <p><b>Healthcare-IT is bitter different from Core IT</b> –
                        <br>
                    </p>
                    <ul>
                        <li>Sometime you need to provide treatment to your patients</li>
                        <li>Sometime needs to inject confidence in your patients or clients on <b>Web</b> (Internet)
                        </li>
                        <li>Sometime you are providing solutions like get appointment to get connected with their
                            respected doctor</li>
                        <li>Sometime needs to provide them modern techniques and features to handle their medical
                            documents too</li>
                    </ul>
                    <p></p>
                    <p>May be sometime you have an mess around you and asking to help, “how to handle Patient Images,
                        what is <b>DICOM</b>, what is <b>PACS</b> System, what is <b>HL7</b> Message system, do <b>Big
                            Data</b> will require in your workaround, <b>Databases</b> which may be small to medium or
                        big, or in your <b>IT infrastructure</b> do you require Servers, what is the meaning of <b>Core
                            Processors</b> and how these are require for your application, how to get speed-up your
                        network and security with ethics, what is the difference between <b>Workstation</b> and
                        <b>Servers</b>, what is the meaning of <b>Clinical Grade Monitors</b> – do you require really.
                        How to remove glitches to make communication better between two branches to get healthcare-data
                        transfer smoothly.
                    </p>
                    <p>One more critical thing is how system integration and automation works, how to integrate
                        different machines to get online to stop human errors, and those machines which are working
                        round-the-clock to serving patients dedicatedly to get treated to their normal life.</p>
                    <div class="container mid-section">
                        <div class="row">
                            <h3>Your Business Goal is important for US - We Can</h3>
                            <span>Core IT &amp; Healthcare IT</span>
                        </div>
                    </div>
                    <br>
                    <p>Let us know if you are stuck with your <b>ERP</b> (<b>SAP</b>) queries and waiting for their
                        resolution, don't worry about their solutions, we have an dedicated team which can support you
                        and available round-the-clock for SAP queries - 'whatever', may be <b>ABAP</b>, <b>BASIS</b>,
                        <b>FICO</b> or <b>Finance</b>, <b>HR</b> or <b>MM</b>, or any other module queries. As well in a
                        part of your database handling and managing, when you are especially talking about
                        <b>ORACLE</b>, we again said "DON'T WORRY". We are with you always - as we have a dedicated team
                        for managing ORACLE database.
                    </p>
                    <p>Boost your business on Internet, you may be have some queries like –
                        <br>
                    </p>
                    <ul>
                        <li>How to develop <b>Web Application</b></li>
                        <li>How to develop <b>Website</b></li>
                        <li>How <b>Website Re-designing</b></li>
                        <li>How <b>Digital Marketing</b> works, <b>SEO</b> and others</li>
                        <li>How to integrate existing social media with your website</li>
                        <li>How to boost your Website to get more customers</li>
                        <li><b>2D-3D Animation</b> for your Website</li>
                        <li>Do you really require <b>Hybrid Application</b> and how its work</li>
                        <li>Do you really require <b>E-Commerce</b> multi-vendor based</li>
                        <li>Do really require your own <b>Social</b> Community</li>
                        <li>What is the criticality of <b>SECURITY</b> concern and how to handle 3<sup>rd</sup> Party
                            Plugins</li>
                        <li>How <b>CMS</b> work, do you can manage by your own.</li>
                    </ul>
                    <p></p>
                    <p>We have an extra ordinary plugins with advance features to boost your Internet market too. In our
                        basket we have a solution for your all queries, ‘whatever’, and waiting for you to discuss and
                        listening you.</p>
                    <p>Yes, dedicatedly we are serving you with our expertise, just let us know <b>WHERE, WHEN, WHY</b>
                        and we will give you how it is possible, we knows better where needs to put weight and from
                        where we need to remove.</p>
                </div>
            </div>
        </div>
    </div>
    <!-- EndContent Section -->
    <div style="background-color: #dadada; padding: 50px 0px;">
        <div class="container" style="margin-bottom: 30px;">
            <div class="row">
                <div class="col-md-4 col-sm-4">
                    <div class="custom_about">
                        <div>
                            <img class="img-responsive"  style="height: 40px; width: 40px" src="../assets/img/gif/business_philosphy.gif">
                        </div>
                        <div class="iner-con">
                            <h2>Business Philosophy</h2>
                            <p>Writing about a company’s business philosophy is very complicated. We are not hiding
                                anything from our client but YES hiding their business secrecy, as LifeLayer knows which
                                is very much important from all aspects.</p>
                            <a href="business-philosophy.html"> Read more</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-4">
                    <div class="custom_about">
                        <div>
                            <img  style="height: 40px; width: 40px;" class="img-responsive" src="../assets/img/gif/our_goal.gif">
                        </div>
                        <div class="iner-con">
                            <h2>Our Goal</h2>
                            <p>LifeLayer specializes in partnering to meet some or all of their service needs, offering
                                both stand-alone and Hybrid Application service, Website and Web Applications that
                                provide targeted, scalable solutions to our clients.</p>
                            <a href="our-goal.html"> Read more</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-4">
                    <div class="custom_about">
                        <div class="">
                            <img  style="height: 40px; width: 40px" class="img-responsive" src="../assets/img/gif/our team.gif">
                        </div>
                        <div class="iner-con">
                            <h2>Our Team</h2>
                            <p>We are ambitious about what we can do and the impact we can make. LifeLayer is made up on
                                team of talented developers, designers, analysts, engineers, marketers and
                                technologists, all dedicated and enjoy our work.
                                <!--and working with the various skillsets and personalities within the team.-->
                            </p>
                            <a href="our-team.html"> Read more</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BannerOtherPages from '@/components/BannerOtherPages.vue';

export default {
    components: {
        BannerOtherPages,
    }
}

</script>

<style scoped>
.banner_content{
    background:url(../assets/img/lifelayer_about.jpg);
    position: relative;
    height: 450px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: grid;
    place-items: center;
    place-content: center;
    }
</style>