<template>
  <BannerOtherPages title="Healthcare ERP" />
  <div>
    <div class="container-fluid">
      <div class="container cont-wraper">
        <div class="site-contant">
          <div class="content">
            <div class="page-title"><h2>HEALTHCARE ERP</h2></div>

            <p>
              First of all finding the right Web Application or Hybrid Application
              Designing and Digital Marketing Company is a big deal and that might be hard
              to classify sometimes. In fact, it might be the biggest decision your
              marketing team as well as your sales team makes in the next few years. Let’s
              help you evaluate if we’re the right one for you.
            </p>
            <p>
              As we knows obviously there are some pros and cons to hiring an outside
              consultant, an expert for SEO, Designer or Developer that you should think
              about before you make that jump.
            </p>
            <h3>Pros</h3>
            <p>
              A good designer and developer knows what attracts and keeps the attention of
              your visitors to become your customers. Research shows that you have less
              than 3 to 10 seconds to get someone to click deeper into your website, and
              having a good visual appeal is very important. Just because you think that
              falling snowflakes or snowfall and animated gifs are cute, doesn’t mean
              everyone does.
            </p>
            <p>
              Search engine optimization is more than just meta tags and keywords based on
              your material. There’s research involved, and while your style of writing
              and displaying the items might be really good, a copywriter or SEO
              consultant could very well help make it incredible and imaginative as well
              as effective.
            </p>
            <p>
              For the help of Sales your Marketing people need to be creative by the very
              nature of the world, and a consultant will help you to move with brilliant
              ideas for spreading the word about your site that you likely have never even
              thought of.
            </p>
            <p>
              Simply you don’t have time to learn all the things you should know about
              marketing a website or blog or your shopping cart too, then hiring a
              consultant or paying for their services will save you an enormous amount of
              time.
            </p>
            <h3>Cons</h3>
            <p>
              <b>Obviously consultants cost money</b>. Good consultants cost a lot of
              money, and you will get what you pay for. Again, you need to spend money to
              make money. Expect to pay anywhere from $40-$200 per hour for quality work.
            </p>
            <p>
              Your judgment is very much important here to identify, hiring someone else
              to do the work for you keeps you in the need for hiring someone else to do
              the work for you. You don’t learn on your own, and you are forced to rely on
              someone else to help you succeed, which means trusting a stranger with your
              livelihood.
            </p>
            <p>
              Anyone with a computer can throw up a website and call themselves an
              “expert”. You have to do some research on the person you’re looking to hire,
              and you have to ask questions. Prepare your own questioner and ask for
              examples, references, and definitely Google them and their company before
              signing any contracts.
            </p>
            <p>
              You need to be willing to not only be learned, but to actually put the time
              into learning and doing what your consultant suggests you, otherwise you’re
              throwing your good money after bad, and you’re going to wind up feeling
              ruined or broke and no further along than you were before. Consultants are
              there to consult you, and if you’re not willing to put an effort into it,
              you’re going to make them feel like their words are going in one ear and out
              from other. Listening is very much important, and if you’re not ready to
              learn, then you’re going to spend more money, paying them to do it for you.
            </p>
            <p>
              And having a good working relationship with a consultant or company that
              offers consulting services can give you long-term results that will
              guarantee you more traffic, more attention, and yes, more money too.
            </p>
            <h3>Are We prepared for Good Fit?</h3>
            <p>
              So are we looking for a strategic relationship, we will help you evaluate if
              we would be good together. We have learned a number of things from the past
              to providing professional services, but none more important than this: Be
              ensure the relationship is a good fit from the start. Here are some common
              characteristics to help renew and revitalize their business ideas.
            </p>
            <ul>
              <li>
                Size and History – May be your company is a small to mid-sized business.
                We can help you to grow your online business as what-when-where and how
                concept. You’ve been in business for at least 1 year (ideally longer) and
                therefore have a good understanding of your own products/services, ideal
                customer, &amp; industry trends.
              </li>
              <li>
                B2B Technology - You have a high-end technology product or service that
                you sell strictly to other businesses, obviously you are B2B. Because you
                have high-end products and services, your sales process is sophisticated
                and involves multiple decision makers which requires a clear and specific
                educational, nurturing, good plan blueprint with closing strategy.
              </li>
              <li>
                Internal Team - There is at least one person internal to your organization
                who already managing your marketing doesn’t matter online or offline. Our
                clients see us as an “extension” to their existing team, who filling in
                the gaps from a digital perspective. Because, we play a complementary role
                to your overall technical, marketing efforts.
              </li>
              <li>
                Money - The customized websites and digital marketing programs which we
                create for you with a technology product/service range from $30,000 to
                $100,000. Each solution is completely tailored to that company and diverse
                in approachable strategy, design, content, and depth of digital marketing
                tactics.
              </li>
            </ul>
            <p></p>
            <h3>Our word with promises</h3>
            <p>
              We realize at the surface, all web consultant, application development
              companies and digital marketing companies can look the same. So, we made
              some bold promises to explain how we think we're a little different and let
              you know why we're.
            </p>
            <p>
              We Will Almost Never Surprise You - We strongly believe in, follow and
              accomplish a 'No Surprise Rule'. As, “Clients should not be surprised by a
              deliverable, owed money, the status of work or overall scope of work with
              achievements.”
            </p>
            <p>
              Our Interactions With You Will Be &amp; Action-Oriented and Productive
              approach - Being busy does not mean we are productive. Every phone call,
              meeting, and discussion will be beneficial to getting things done.
            </p>
            <p>
              When needed, we also put you in direct communication with designers and
              developers. We feel that in order to have the most productive interactions,
              you need to collaborate with the people doing the work.
            </p>
            <p>
              We Will Always Strive To Do It Better Next Time - We will chase perfection.
              But along the way, we shall catch excellence. Our word and promise to you is
              that we’ll always dedicate ourselves to learning, experimenting, and
              applying our website expertise to bettering your website.
            </p>
            <p>
              We Will Treat Your Investment As If It Were Our Own - We are especially
              grateful for your business and we do not take your hard earned investment
              lightly.
            </p>
            <h3>Pricing</h3>
            <p>
              Unless you plant a money trees, everybody asks you, "How much does it cost?"
              we know money isn’t everything, but its many things.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BannerOtherPages from "@/components/BannerOtherPages.vue";
export default {
  components: {
    BannerOtherPages,
  },
};
</script>
<style scoped>

.banner_content{
background:url(../assets/img/gif/healthcare_erp.jpg);
position: relative;
height: 450px;
width: 100%;
background-size: cover;
background-repeat: no-repeat;
background-position: center;
display: grid;
place-items: center;
place-content: center;
}</style>
