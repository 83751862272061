<template>
  <BannerOtherPages title="Email Marketing" />
  <div>
    <!-- About Us Content Section -->
    <div class="container-fluid cont-wraper">
      <div class="container">
        <div class="site-content">
          <div class="content">
            <div class="page-title">
              <h2 class="text-center">YOUR OWN EMAIL MARKETING OR DIGITAL MARKETING</h2>
            </div>
            <p>
              Email marketing is essential for your business. Whether you're using an application, plugin, or tool, our Email Marketing System offers an easy-to-use, cost-effective solution that gives you full control and helps you stay connected with your audience.
            </p>
            <p>
              While platforms like Gmail or Outlook work well for personal use, businesses require dedicated solutions to manage larger groups and scheduled sends. This is where email marketing excels, making it easy to communicate with your audience on a larger scale.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Benefits Section -->
    <section class="container py-5">
      <h2 class="text-center mb-4">Key Benefits of Our Email Marketing System</h2>
      <div class="row row-cols-1 row-cols-md-2 g-4">
        <div class="col">
          <div class="card shadow-sm h-100">
            <div class="card-body">
              <h5 class="card-title">1. Cost-Effective and Scalable</h5>
              <p class="card-text">
                Our platform offers a scalable solution for all business sizes. Start small, and grow without worrying about excessive costs.
              </p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card shadow-sm h-100">
            <div class="card-body">
              <h5 class="card-title">2. Advanced Analytics and Insights</h5>
              <p class="card-text">
                Gain valuable insights into your campaigns with real-time analytics, including open rates, click-through rates, and more.
              </p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card shadow-sm h-100">
            <div class="card-body">
              <h5 class="card-title">3. Customizable Email Templates</h5>
              <p class="card-text">
                Choose from a variety of pre-designed templates or create your own to suit your brand's unique needs.
              </p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card shadow-sm h-100">
            <div class="card-body">
              <h5 class="card-title">4. Automation and Scheduling</h5>
              <p class="card-text">
                Automate your email campaigns and schedule them in advance to ensure timely delivery to your subscribers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Why Choose Us Section -->
    <section class="bg-light py-5">
      <div class="container">
        <h2 class="text-center mb-4">Why Choose Our Email Marketing Platform?</h2>
        <p class="text-center">
          We provide a reliable, secure, and efficient platform that helps businesses connect with their customers seamlessly. Here's why you should choose us:
        </p>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">✔️ Easy to Use Interface</li>
          <li class="list-group-item">✔️ Superior Customer Support</li>
          <li class="list-group-item">✔️ Enhanced Data Security</li>
          <li class="list-group-item">✔️ Seamless Integration with CRM Tools</li>
        </ul>
      </div>
    </section>

    <!-- Call to Action Section -->
    <section class="container py-5 text-center">
      <h2 class="mb-4">Ready to Transform Your Email Marketing?</h2>
      <p>
        Take your email marketing to the next level with our powerful tools and exceptional support. Start building lasting customer relationships today!
      </p>
      <button class="btn btn-primary btn-lg mt-3">Get Started Now</button>
    </section>
  </div>
</template>

<script>
import BannerOtherPages from "@/components/BannerOtherPages.vue";
export default {
  components: {
    BannerOtherPages,
  },
};
</script>

<style scoped>

.banner_content{
background:url(../assets/img/gif/email.png);
position: relative;
height: 450px;
width: 100%;
background-size: cover;
background-repeat: no-repeat;
background-position: center;
display: grid;
place-items: center;
place-content: center;
}
.page-title h2 {
  font-weight: bold;
  
}

.card {
  transition: transform 0.3s ease-in-out;
}
.card:hover {
  transform: translateY(-5px);
}
ul.list-group li {
  font-size: 1.2rem;
  padding: 15px 10px;
}

.btn-primary {
  background-color: #007bff;
  border: none;
}
.btn-primary:hover {
  background-color: #0056b3;
}
.btn.btn-primary {
    background: #0056b3;
    color: #fff;
    padding: 10px 20px;
}
</style>
