<template>
    <BannerOtherPages title="Digital Marketing" />
    <div>

      <section class="services-section py-5 bg-light">
        <div class="container cont-wraper">
          <h2 class="fw-bold text-center mb-4">Our Services Tailored for Results</h2>
          <div class="row g-4">
            <div v-for="(service, index) in services" :key="index" class="col-md-6 col-lg-4">
              <div class="card shadow-sm border-0 h-100">
                <div class="card-body text-center">
                  <i :class="service.icon" class="fs-1 text-primary mb-3"></i>
                  <h5 class="fw-bold">{{ service.title }}</h5>
                  <p class="text-muted">{{ service.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  
      <!-- Client Impact Section -->
      <section class="impact-section py-5 bg-white text-center">
        <div class="container">
          <h2 class="fw-bold mb-4">Transforming Brands, Driving Impact</h2>
          <div class="row g-4">
            <div class="col-md-4" v-for="(impact, index) in impacts" :key="index">
              <div class="impact-box">
                <h3 class="text-primary fw-bold">{{ impact.value }}</h3>
                <p class="text-muted">{{ impact.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
  
      <!-- Testimonials Section -->
      <section class="testimonials-section py-5 bg-light text-center">
        <div class="container">
          <h2 class="fw-bold mb-4">See What Our Clients Are Saying</h2>
          <div class="row g-4">
            <div v-for="(testimonial, index) in testimonials" :key="index" class="col-md-6">
              <div class="testimonial-box p-4 shadow-sm border rounded">
                <p class="text-muted">"{{ testimonial.text }}"</p>
                <h6 class="fw-bold mt-3">{{ testimonial.name }}</h6>
                <small class="text-primary">{{ testimonial.role }}</small>
              </div>
            </div>
          </div>
        </div>
      </section>
  
      <!-- Call-to-Action Section -->
      <section class="cta-section py-5 bg-dark text-white text-center">
        <div class="container">
          <h2 class="fw-bold">Let’s Build Your Digital Success Story</h2>
          <p class="lead mb-4">It’s time to redefine your business with a digital-first approach.</p>
          <button class="btn btn-primary btn-lg fw-bold me-3">Start Now</button>
          <button class="btn btn-outline-light btn-lg fw-bold">Book a Free Strategy Session</button>
        </div>
      </section>
    </div>
  </template>

<script>
import BannerOtherPages from "@/components/BannerOtherPages.vue";

export default {
  components: {
    BannerOtherPages,
  },
  data() {
    return {
      services: [
        { title: "Performance Marketing", description: "Maximize ROI through targeted ads.", icon: "bi bi-bar-chart-fill" },
        { title: "SEO", description: "Increase organic visibility.", icon: "bi bi-search" },
        { title: "Social Media Marketing", description: "Engage on platforms like Instagram.", icon: "bi bi-facebook" },
      ],
      impacts: [
        { value: "250%", description: "Traffic boost for a healthcare client." },
        { value: "70%", description: "Sales growth for an e-commerce platform." },
      ],
      testimonials: [
        { text: "Lifelayer boosted our revenue with PPC!", name: "Amit Khanna", role: "E-commerce Manager" },
      ],
    };
  },
};
</script>

<style scoped>
.hero-section h2 {
  font-size: 3rem;
  font-weight: 600;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.hero-section .btn {
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.hero-section .btn:hover {
  background-color: #1e3a8a;
  transform: scale(1.05);
}

/* Why Choose Us Section */
.icon-circle {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 2.5rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.icon-circle:hover {
  transform: scale(1.1);
}

/* Services Section */
.services .card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.services .card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

/* Testimonials Section */
.testimonials .card {
  border: 1px solid #ddd;
  background-color: transparent;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.testimonials .card:hover {
  transform: translateY(-5px);
}

/* Call to Action Section */
.cta {
  background-color: #1e3a8a;
  color: white;
}

.cta h2 {
  font-size: 2.5rem;
  font-weight: 600;
}

.cta p {
  font-size: 1.2rem;
  font-weight: 500;
}

.cta .btn {
  background-color: #f7b731;
  transition: background-color 0.3s ease;
}

.cta .btn:hover {
  background-color: #d69829;
}
</style>
