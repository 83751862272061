<template>
  <section class="navigation">
    <div class="nav-container">
      <div class="brand">
        <router-link to="/"><img src="../assets/img/lifelayer_logo.svg" alt="" height="62px">
        <span style="position:relative; top:18px;left:-12px;font-size: 22px;">Lifelayer</span></router-link>
      </div>
      <nav>
        <div class="nav-mobile">
          <button id="nav-toggle" @click="toggleNav" :class="{ active: isNavActive }">
            <span v-if="!isNavActive" class="menu-icon"><i class="bi bi-list"></i></span>
            <span v-if="isNavActive" class="close-icon">✖</span>
          </button>
        </div>
        <ul class="nav-list" :style="{ display: isNavVisible ? 'block' : 'none' }"> 

          <li><router-link to="/">Home</router-link></li>
          <li @click.stop="toggleDropdown('about_Us')">
            <a> About Us <span class="dropdown-icon">▾</span> </a>
            <ul
              class="nav-dropdown"
              :style="{ display: dropdowns.about_Us ? 'block' : 'none' }"
            >
              <li><router-link to="/About_Us"> About Us</router-link></li>
              <li>
                <router-link to="/Technology_life"
                  >Technology in our life</router-link
                >
              </li>
              <li>
                <router-link to="/Business_Philosophy">Business Philosophy</router-link>
              </li>
              <li><router-link to="/OurTeam">Our Team</router-link></li>
              <li><router-link to="/Our_Goal">Our Goal</router-link></li>
            </ul>
          </li>
          <li @click.stop="toggleDropdown('hire_Us')">
            <a> Hire Us <span class="dropdown-icon">▾</span> </a>
            <ul
              class="nav-dropdown"
              :style="{ display: dropdowns.hire_Us ? 'block' : 'none' }"
            >
              <li>
                <router-link to="/Healthcare_Consulting"
                  >Healthcare Consulting</router-link
                >
              </li>
              <li>
                <router-link to="/healthcareItConsulting"
                  >Healthcare IT Consulting</router-link
                >
              </li>
              <li><router-link to="/ERP_Consulting">ERP Consulting</router-link></li>
              <li>
                <router-link to="/Digital_Marketing">Digital Marketing</router-link>
              </li>
              <li><router-link to="/Web_Consulting">Web Consulting</router-link></li>

              <li><router-link to="/ItSupport">IT Support</router-link></li>

              <li>
                <router-link to="/oracleDataBaseSupport">Database Management</router-link>
              </li>
            </ul>
          </li>
          <li @click.stop="toggleDropdown('education')">
            <a> Education <span class="dropdown-icon">▾</span> </a>
            <ul
              class="nav-dropdown"
              :style="{ display: dropdowns.education ? 'block' : 'none' }"
            >
              <li><router-link to="/healthcareERP">Healthcare ERP</router-link></li>
              <li>
                <router-link to="/radiologyOnMobile">Radiology on mobile</router-link>
              </li>
              <li>
                <router-link to="/appointmentCalender">Appointment calendar</router-link>
              </li>
              <li><router-link to="/emailMarketing">Email Marketing</router-link></li>
              <li>
                <router-link to="/socialMediaPlayform">Social Media Platform</router-link>
              </li>
              <li><router-link to="/EducationERP">Education ERP</router-link></li>
              <li><router-link to="/JobBoard">job Board</router-link></li>
              <li><router-link to="/HealthTracker">Health Tracker</router-link></li>
            </ul>
          </li>

          <li><router-link to="/Career">Career</router-link></li>
          <li class="quote_Btn"><router-link to="/Contact_Us">Get a Quote</router-link></li>
        </ul>
      </nav>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      isNavVisible: false,
      isNavActive: false,
      dropdowns: {
        about_Us: false,
        hire_Us: false,
        education: false,
      },
    };
  },
  methods: {
    toggleNav() {
      this.isNavVisible = !this.isNavVisible;
      this.isNavActive = !this.isNavActive;
    },
    toggleDropdown(dropdown) {
      // Close other dropdowns
      Object.keys(this.dropdowns).forEach((key) => {
        if (key !== dropdown) this.dropdowns[key] = false;
      });
      // Toggle the selected dropdown
      this.dropdowns[dropdown] = !this.dropdowns[dropdown];
    },
    closeAllDropdowns() {
      // Close all dropdowns when clicking outside
      Object.keys(this.dropdowns).forEach((key) => {
        this.dropdowns[key] = false;
      });
    },
  },
  mounted() {
    // Listen for clicks outside the component
    document.addEventListener("click", this.closeAllDropdowns);
  },
  beforeUnmount() {
    // Remove event listener
    document.removeEventListener("click", this.closeAllDropdowns);
  },
};
</script>

<style scoped>
.quote_Btn a{
    background-color: #4460b3;
    color: white;
    padding: 10px 30px;
    border-radius: 30px;
    width: fit-content;
}

.navigation {
  height: 70px;
  background: #ffffff;
  position: fixed;
  width: 100%;
  z-index: 9999;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0px auto;
}

.brand {
  line-height: 70px;
  text-transform: capitalize;
  font-size: 1.4em;
  font-weight: 600;
}

.brand a {
  color:#4460b3;
  text-decoration: none;
}

nav {
 display: flex;
 align-items: center;
 background-color: white;

}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  float: left;
  position: relative;
}

nav ul li a {
  display: block;
  padding: 10px 20px;
  background: #ffffff;
  color: #463f3f;
  text-decoration: none;
  font-size: 14px;
}

nav ul li a:hover {
  background: #f2f2f2;
  color: #4460b3;
}

nav ul li a:not(:only-child):after {
  padding-left: 4px;
}

nav ul li ul li {
  min-width: 190px;
}

nav ul li ul li a {
  padding: 8px;
  line-height: 20px;
}

.nav-dropdown {
  position: absolute;
  z-index: 1;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: none;
  background-color: white;
}

.nav-mobile {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  height: 70px;
  width: 70px;
}

.dropdown-icon {
  font-size: 0.8em;
  margin-left: 5px;
}

.menu-icon {
  font-size: 1.5em;
  color: #463f3f;
}

.close-icon {
  font-size: 1.5em;
  color:#4460b3;
}



.nav-list > li >a {
    margin: 0px 5px;
}
a{
    cursor: pointer !important;
}

@media only screen and (max-width: 868px) {
  .nav-mobile {
    display: block;
  }

  nav {
    width: 100%;
    padding: 55px 0 0px;
  }

  nav ul {
    display: none;
  }

  nav ul li {
    float: none;
  }

  nav ul li a {
    padding: 8px;
    line-height: 20px;
  }

  nav ul li ul {
    position: static;
  }

  nav ul li ul li a {
    padding-left: 30px;
  }
  .brand {
    padding-left: 20px;
    float: left;
    line-height: 70px;
    text-transform: capitalize;
    font-size: 1.4em;
    font-weight: 600;
    position: absolute;
  }
  .nav-list {
    width: 100% !important;
    padding: 40px 10px;
    position: relative;
    top: 18px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: white;
  }

  .nav-dropdown{
    height: 250px;
    overflow-y: scroll;
  }
}

#nav-toggle {
  position: absolute;
  left: 18px;
  top: 15px;
  cursor: pointer;
  background-color: white;
  border: none;
}
#nav-toggle i {
  font-size: 30px;
}

@media screen and (min-width: 800px) {
  .nav-list {
    display: block !important;
   
  }
}
</style>
