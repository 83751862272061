import { createApp } from 'vue';




// import './assets/styles/tailwind.css';

import App from './App.vue';
import router from './routes';
import Toast from "vue3-toastify";
import 'vue3-toastify/dist/index.css';

// Import Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

// Import Bootstrap JavaScript bundle (includes Popper.js)
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '@fortawesome/fontawesome-free/css/all.css'; // Import Font Awesome CSS
import 'lineicons/dist/lineicons.css';



const app = createApp(App);

// Optional: If you still want to use jQuery
import $ from 'jquery';
app.config.globalProperties.$ = $;

app.use(router);
app.use(Toast);
app.mount('#app');
