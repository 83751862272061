<template>
  <div>
    <!-- Hero Banner -->
    <BannerOtherPages title="Health Tracker" />
    <div class="container" style="margin-top: -6px">
      <div class="page-title mt-4 text-center">
        <h1 class="display-4 fw-bold text-primary">
          Your Health, Your Priority
        </h1>
        <p class="lead text-muted">
          A comprehensive tool to help you track your health, stay motivated, and achieve your fitness goals.
        </p>
        <button class="btn btn-success btn-lg mt-3">
          Start Tracking Now <i class="bi bi-arrow-right"></i>
        </button>
      </div>
    </div>

    <!-- Features Section -->
    <section class="container py-5">
      <h2 class="text-center mb-5 fw-bold text-primary">Features You'll Love</h2>
      <div class="row g-4">
        <div class="col-md-4 text-center">
          <div class="p-4 shadow rounded-3 feature-card">
            <i class="bi bi-heart-pulse display-3 text-danger"></i>
            <h3 class="mt-3">Heart Rate Monitoring</h3>
            <p class="text-muted">Monitor your heart rate effortlessly in real-time.</p>
          </div>
        </div>
        <div class="col-md-4 text-center">
          <div class="p-4 shadow rounded-3 feature-card">
            <i class="bi bi-graph-up-arrow display-3 text-success"></i>
            <h3 class="mt-3">Activity Insights</h3>
            <p class="text-muted">Receive detailed insights into your daily activities.</p>
          </div>
        </div>
        <div class="col-md-4 text-center">
          <div class="p-4 shadow rounded-3 feature-card">
            <i class="bi bi-person-check display-3 text-info"></i>
            <h3 class="mt-3">Custom Goals</h3>
            <p class="text-muted">Set, track, and achieve personalized health goals.</p>
          </div>
        </div>
        <div class="col-md-4 text-center">
          <div class="p-4 shadow rounded-3 feature-card">
            <i class="bi bi-droplet-half display-3 text-primary"></i>
            <h3 class="mt-3">Hydration Tracker</h3>
            <p class="text-muted">Stay hydrated with alerts and detailed logs.</p>
          </div>
        </div>
        <div class="col-md-4 text-center">
          <div class="p-4 shadow rounded-3 feature-card">
            <i class="bi bi-sun display-3 text-warning"></i>
            <h3 class="mt-3">Sleep Analysis</h3>
            <p class="text-muted">Analyze and improve your sleep quality.</p>
          </div>
        </div>
        <div class="col-md-4 text-center">
          <div class="p-4 shadow rounded-3 feature-card">
            <i class="bi bi-bar-chart display-3 text-secondary"></i>
            <h3 class="mt-3">Weight Management</h3>
            <p class="text-muted">Track your weight and BMI effortlessly.</p>
          </div>
        </div>
      </div>
    </section>

    <!-- User Dashboard Overview Section -->
    <section class="container py-5">
      <h2 class="text-center mb-5 fw-bold text-primary">User Dashboard Overview</h2>
      <div class="row">
        <div class="col-md-6">
          <img src="../assets/img/gif/dashboard.jpg" alt="Dashboard" class="img-fluid rounded-3 shadow-lg">
        </div>
        <div class="col-md-6">
          <h3 class="text-primary">Your Health at a Glance</h3>
          <p class="text-muted">
            Your personalized dashboard provides real-time insights into your health data. From tracking your heart rate to
            viewing daily activity logs, everything you need to monitor your progress is right at your fingertips.
          </p>
          <ul>
            <li>Heart rate monitoring in real-time.</li>
            <li>Custom goal tracking to help you stay focused.</li>
            <li>Comprehensive reports on your activity and sleep patterns.</li>
            <li>Easy-to-read graphs and metrics for quick health assessments.</li>
          </ul>
        </div>
      </div>
    </section>

    <!-- Tips for Effective Tracking Section -->
    <section class="bg-light py-5">
      <div class="container text-center">
        <h2 class="fw-bold text-primary">Tips for Effective Health Tracking</h2>
        <p class="text-muted">Get the most out of your tracker with these helpful tips.</p>
        <div class="row g-4 mt-4">
          <div class="col-md-4">
            <div class="card shadow border-0">
              <div class="card-body">
                <i class="bi bi-check-circle display-4 text-success"></i>
                <h5 class="card-title mt-3">Consistency is Key</h5>
                <p class="text-muted">
                  Track your health data daily to see the best results. Consistency helps you stay on top of your goals.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card shadow border-0">
              <div class="card-body">
                <i class="bi bi-lightning-charge display-4 text-warning"></i>
                <h5 class="card-title mt-3">Set Realistic Goals</h5>
                <p class="text-muted">
                  Don’t set yourself up for failure. Start with small, achievable goals and build up to more challenging ones.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card shadow border-0">
              <div class="card-body">
                <i class="bi bi-patch-check display-4 text-info"></i>
                <h5 class="card-title mt-3">Use Insights to Improve</h5>
                <p class="text-muted">
                  Regularly check your progress and use insights to adjust your habits. Small tweaks can make a big difference.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

   
  
  </div>
</template>

<script>
import BannerOtherPages from "@/components/BannerOtherPages.vue";
export default {
  components: {
    BannerOtherPages,
  },
};
</script>

<style scoped>
.banner_content{
    background:url(../assets/img/gif/meeting.jpg);
    position: relative;
    height: 450px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: grid;
    place-items: center;
    place-content: center;
    }
.feature-card {
  background: #ffffff;
  transition: transform 0.3s, box-shadow 0.3s;
}
.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}
</style>
