<template>
  <div>
    <BannerOtherPages title="Our Team" />
    <div class="container-fluid">
      <div class="container cont-wraper">
        <!-- Introduction Section -->
        <div class="site-content">
          <div class="content">
            <div class="page-title">
              <h2>ABOUT LIFELAYER FAMILY</h2>
            </div>
            <p>
              We are ambitious about what we can do and the impact we can make.
              LifeLayer is made up of talented developers, designers, analysts, engineers,
              marketers, and technologists, all dedicated to achieving excellence.
            </p>
            <p>
              Every member of the LifeLayer family believes strongly in the empowering
              power of knowledge. We look forward to working with you on your next project.
            </p>
          </div>
        </div>

        <!-- Team Members Section -->
        <div class="team-section">
          <h3 class="section-title">Meet Our Team</h3>
          <div class="row">
            <div class="col-md-4 col-sm-6" v-for="member in teamMembers" :key="member.id">
              <div class="team-card-modern">
                <div class="card-header">
                  <h4 class="name">{{ member.name }}</h4>
                  <p class="role">{{ member.role }}</p>
                </div>
                <div class="card-body">
                  <p class="description">
                    {{ member.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Why Choose Us Section -->
        <div class="why-choose-section">
          <h3 class="section-title">Why Choose Us?</h3>
          <div class="row">
            <div class="col-md-4 col-sm-6" v-for="reason in reasons" :key="reason.id">
              <div class="why-card">
                <div class="icon-container">
                  <i :class="reason.icon" class="icon"></i>
                </div>
                <h4 class="reason-title">{{ reason.title }}</h4>
                <p class="reason-description">{{ reason.description }}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Call-to-Action -->
        <div class="cta-section">
          <p>Want to work with our amazing team?</p>
          <button class="btn btn-primary">Get in Touch</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BannerOtherPages from '@/components/BannerOtherPages.vue';

export default {
  components: {
    BannerOtherPages,
  },
  data() {
    return {
      teamMembers: [
        {
          id: 1,
          name: "Tarun",
          role: "Software Developer",
          description: "An innovative problem-solver with a passion for developing scalable applications.",
        },
        {
          id: 2,
          name: "Janvi",
          role: "UI/UX Designer",
          description: "Creating intuitive user experiences with a knack for modern design trends.",
        },
        {
          id: 3,
          name: "Mahima",
          role: "Project Manager",
          description: "Ensuring projects run smoothly while maintaining high-quality standards.",
        },
      ],
      reasons: [
        {
          id: 1,
          icon: "fas fa-cogs",
          title: "Innovative Solutions",
          description: "We develop creative and scalable solutions tailored to your business needs.",
        },
        {
          id: 2,
          icon: "fas fa-users",
          title: "Expert Team",
          description: "Our skilled team is committed to delivering top-notch services.",
        },
        {
          id: 3,
          icon: "fas fa-briefcase",
          title: "Proven Experience",
          description: "Years of experience in delivering successful projects across industries.",
        },
      ],
    };
  },
};
</script>

<style scoped>


.page-title h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
}

.section-title {
  font-size: 2rem;
  margin: 30px 0;
  text-align: center;
  color: #333;
}

/* Team Section */
.team-section {
  margin-top: 40px;
}

.team-card-modern {
  background: linear-gradient(145deg, #f7f8fa, #e0e3e8);
  border-radius: 12px;
  padding: 20px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1), -5px -5px 10px rgba(255, 255, 255, 0.7);
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}

.team-card-modern:hover {
  transform: translateY(-5px);
}

.card-header {
  text-align: center;
  margin-bottom: 15px;
}

.name {
  font-size: 1.5rem;
  font-weight: bold;
  color: #007bff;
}
.role {
  font-size: 1.1rem;
  color: #555;
  margin-top: 5px;
  font-style: italic;
}

.card-body {
  text-align: justify;
}

.description {
  color: #333;
  font-size: 1rem;
}

/* Why Choose Us Section */
.why-choose-section {
  margin-top: 40px;
  background: #f9f9f9;
  padding: 30px 20;
}

.why-card {
  background: #ffffff;
  border-radius: 12px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}

.why-card:hover {
  transform: translateY(-5px);
}

.icon-container {
  background: #007bff;
  color: #fff;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto 15px;
  font-size: 1.5rem;
}

.reason-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.reason-description {
  color: #555;
  font-size: 1rem;
}

/* Call-to-Action */
.cta-section {
  text-align: center;
  margin: 40px 0;
}

.cta-section .btn {
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 8px;
}
</style>
