<template>
    <BannerOtherPages title="Radiology On Mobile" />
    <div>
      <div class="container-fluid">
        <div class="container cont-wraper">
          <div class="site-content">
            <!-- Page Title -->
            <div class="page-title text-center">
              <h2>SOLUTION FOR YOUR HEALTH IT (HIT), RADIOLOGY OR TELERADIOLOGY</h2>
            </div>
  
            <!-- Introduction Section -->
            <section class="text-center my-4">
              <p class="lead">
                We provide comprehensive radiology and teleradiology solutions designed to meet the evolving needs of healthcare providers, radiologists, and imaging centers. Our innovative approach ensures efficiency, accuracy, and seamless integration into your healthcare ecosystem.
              </p>
            </section>
  
            <!-- Key Solutions Section -->
            <section class="py-5">
              <h3 class="text-center mb-4">Our Key Radiology Solutions</h3>
              <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
                <div class="col">
                  <div class="card h-100 shadow-sm">
                    <div class="card-body">
                      <h5 class="card-title">PACS (Picture Archiving & Communication System)</h5>
                      <p class="card-text">
                        Streamline the storage, retrieval, and distribution of medical images with our advanced PACS technology designed for healthcare providers.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card h-100 shadow-sm">
                    <div class="card-body">
                      <h5 class="card-title">Radiology Workflow Solutions</h5>
                      <p class="card-text">
                        Enhance radiology department efficiency with our seamless PACS & RIS workflow solutions tailored for radiologists and imaging centers.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card h-100 shadow-sm">
                    <div class="card-body">
                      <h5 class="card-title">Imaging Solutions for Radiologists</h5>
                      <p class="card-text">
                        Equip radiologists with cutting-edge workstations and medical-grade monitor systems for precise image analysis and reporting.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card h-100 shadow-sm">
                    <div class="card-body">
                      <h5 class="card-title">Teleradiology Sales & Support</h5>
                      <p class="card-text">
                        Our expert team provides end-to-end support for teleradiology services, including RIS-PACS integration, installation, and maintenance.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card h-100 shadow-sm">
                    <div class="card-body">
                      <h5 class="card-title">Custom Integration Services</h5>
                      <p class="card-text">
                        Seamlessly integrate teleradiology applications and PACS systems with existing healthcare modalities for a unified workflow.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card h-100 shadow-sm">
                    <div class="card-body">
                      <h5 class="card-title">Comprehensive IT Support</h5>
                      <p class="card-text">
                        From software updates to hardware maintenance, our IT support services ensure your radiology operations run smoothly.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
  
            <!-- Why Choose Us Section -->
            <section class="bg-light py-5">
              <div class="container">
                <h3 class="text-center mb-4">Why Choose Our Radiology Solutions?</h3>
                <p class="text-center">
                  We are committed to delivering high-quality radiology solutions that enhance patient care, streamline workflows, and ensure compliance with industry standards.
                </p>
                <ul class="list-group list-group-flush mt-4">
                  <li class="list-group-item">✔️ Advanced Medical Imaging Technology</li>
                  <li class="list-group-item">✔️ Seamless Workflow Integration</li>
                  <li class="list-group-item">✔️ Dedicated Customer Support</li>
                  <li class="list-group-item">✔️ Customizable Solutions for Unique Needs</li>
                  <li class="list-group-item">✔️ Secure and Compliant Systems</li>
                </ul>
              </div>
            </section>
  
            <!-- Call to Action Section -->
            <section class="container py-5 text-center">
              <h3 class="mb-4">Ready to Transform Your Radiology Experience?</h3>
              <p>
                Contact us today to learn more about how our radiology solutions can benefit your organization and improve patient outcomes.
              </p>
              <button class="btn btn-primary btn-lg mt-3">Contact Us</button>
            </section>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import BannerOtherPages from "@/components/BannerOtherPages.vue";
  
  export default {
    components: {
      BannerOtherPages,
    },
  };
  </script>
  
  <style scoped>
  
  .banner_content{
  background:url(../assets/img/gif/radiology.jpg);
  position: relative;
  height: 450px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  display: grid;
  place-items: center;
  place-content: center;
  }
  .page-title h2 {
    font-weight: bold;
   
  }
  
  .lead {
    font-size: 1.25rem;
    color: #555;
  }
  
  .card {
    transition: transform 0.3s ease-in-out;
  }
  .card:hover {
    transform: translateY(-5px);
  }
  
  .btn-primary {
    background-color: #007bff;
    border: none;
  }
  .btn-primary:hover {
    background-color: #0056b3;
  }
  .btn.btn-primary {
    background: #007bff;
    color: #fff;
    padding: 10px 20px;
}
  
  ul.list-group li {
    font-size: 1.1rem;
  }
  </style>