<template>
  <footer class="footer_area section_padding_130_0">
    <div class="container">
      <div class="row">
        <!-- Single Widget-->
        <div class="col-12 col-sm-6 col-lg-4">
          <div class="single-footer-widget section_padding_0_130">
            <div style="padding-top: 0px; color: #bbb">
              <router-link to="/" style="text-decoration: none;">
              <img src="../assets/img/lifelayer_logo.svg" alt="" height="62px">
                <span style="position:relative; top:20px;left:-12px;font-size: 20px;">Lifelayer</span></router-link>
            <p style="text-align: justify;">Lifelayer Health Solutions Pvt. Ltd. is a product-based IT company specializing in healthcare solutions. It focuses on innovative digital tools and applications to enhance patient care and streamline healthcare processes."</p>
              
              
              <p>
              Contact Us: <br> +91 7770001380 | info@lifelayer.org
              </p>
            </div>
            <!-- Footer Social Area-->
            <div class="footer_social_area">
              <a href="https://www.facebook.com/"><i class="bi bi-facebook"></i></a>
              <a href="https://x.com/"><i class="bi bi-twitter"></i></a>
              <a
                href="https://www.linkedin.com/company/lifelayer-health-solutions-pvt-ltd/"
                ><i class="bi bi-linkedin"></i
              ></a>
              <a href="https://www.instagram.com/"><i class="bi bi-instagram"></i></a>
            </div>
          
          </div>
        </div>
        <!-- Single Widget-->
        <div class="col-12 col-sm-6 col-lg">
          <div class="single-footer-widget section_padding_0_130">
            <!-- Widget Title-->
            <h5 class="widget-title">About</h5>
            <hr>
            <!-- Footer Menu-->
            <div class="footer_menu">
              <ul>
                <li>
                  <router-link to="/About_Us"> About Us</router-link>
                </li>
                <li>
                  <router-link to="/Technology_life">Technology In Our Life</router-link>
                </li>
                <li>
                  <router-link to="/Business_Philosophy">Business Philosophy</router-link>
                </li>
                <li>
                  <router-link to="/OurTeam">Our Team</router-link>
                </li>
                <li>
                  <router-link to="Our_Goal">Our Goal</router-link>
                </li>
                <li><router-link to="/Chatting_System">Chatting System</router-link></li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Single Widget-->
        <div class="col-12 col-sm-6 col-lg">
          <div class="single-footer-widget section_padding_0_130">
            <!-- Widget Title-->
            <h5 class="widget-title">Hire Us</h5>
            <hr>
            <!-- Footer Menu-->
            <div class="footer_menu">
              <ul>
                <li>
                  <router-link to="/Healthcare_Consulting"
                    >HealthCare Consulting</router-link
                  >
                </li>
                <li>
                  <router-link to="/healthcareItConsulting"
                    >HealthCare IT Consulting</router-link
                  >
                </li>
                <li>
                  <router-link to="/ERP_Consulting">ERP Consulting</router-link>
                </li>
                <li>
                  <router-link to="/Digital_Marketing">Digital Marketing</router-link>
                </li>
                <li>
                  <router-link to="/Web_Consulting">Web Consulting</router-link>
                </li>
                <li><router-link to="/BlockPage">Blacklist Employees </router-link></li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Single Widget-->
        <div class="col-12 col-sm-6 col-lg">
          <div class="single-footer-widget section_padding_0_130">
            <!-- Widget Title-->
            <h5 class="widget-title">Education</h5>
            <hr>
            <!-- Footer Menu-->
            <div class="footer_menu">
              <ul>
                <li>
                  <router-link to="/healthcareERP">Healthcare ERP</router-link>
                </li>
                <li>
                  <router-link to="/radiologyOnMobile">Radiology On Mobile</router-link>
                </li>
                <li>
                  <router-link to="/appointmentCalender"
                    >Appoinement Calender</router-link
                  >
                </li>
                <li>
                  <router-link to="/Digital_Marketing">Digital Marketing</router-link>
                </li>
                <li>
                  <router-link to="/socialMediaPlayform"
                    >Social Media Platform</router-link
                  >
                </li>
                <li>
                  <router-link to="/EducationERP">Education ERP</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Contact Information -->
        <!-- Copyright -->
        <hr style="position: relative; border: 1px solid gray;top: 50px">
        <div
          style="
            text-align: center;
            padding-top: 60px;
            color: #bbb;
          "
        >
          © 2025 BY Lifelayer Health Solutions Pvt. Ltd | ALL RIGHTS RESERVED .
        </div>
      </div>
    </div>
    <div>
      <div style="z-index: 9999; display:none">
        <button
          @click="toggleChatbox"
          style="
            display:none;
            border-radius: 50%;
            height: 60px;
            position: fixed;
            width: 60px;
            bottom: 20px;
            right: 20px;
            border: none;
            overflow: hidden;
          "
          type="button"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-title="See the Thoughts"
          ref="tooltipButton"
        >
          <img
            style="height: 100%; width: 100%; object-fit: cover"
            src="../assets/img/gif/typing.gif"
            alt=""
          />
        </button>

        <div
          v-if="isChatboxVisible"
          class="chatbox card shadow-lg"
          style="width: 400px; height: 500px"
        >
          <div
            class="card-header bg-success text-white d-flex justify-content-between align-items-center"
          >
            <div class="d-flex align-items-center">
              <img
                src="your-logo.png"
                alt="Apollo"
                class="rounded-circle me-2"
                width="30"
                height="30"
              />
              <span><strong>Apollo AI Assistant</strong></span>
            </div>
            <button class="btn-close btn-close-white" @click="toggleChatbox"></button>
          </div>

          <div class="card-body chatbox-body" style="overflow-y: auto; height: 380px">
            <!-- Increased body height -->
            <p class="text-success fw-bold">
              👋 Hi! I'm Apollo, an AI Assistant. Ask me anything about Lifelayer!
            </p>

            <div class="bg-light p-2 rounded mb-2 user-message">
              <span>What services does Lifelayer offer?</span>
            </div>
            <div class="bg-light p-2 rounded response-message">
              Lifelayer provides IT solutions and healthcare management systems, focusing
              on digital marketing, CMS web development, and app development.
            </div>

            <div class="bg-light p-2 rounded mb-2 user-message">
              <span>How do I contact customer support?</span>
            </div>
            <div class="bg-light p-2 rounded response-message">
              You can contact our support team through the 'Contact Us' section on our
              website or by sending us an email at support@lifelayer.com.
            </div>
          </div>

          <div class="card-footer">
            <input
              v-model="message"
              type="text"
              class="form-control"
              placeholder="Type and press [Enter]..."
              @keypress.enter="sendMessage"
            />
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
// Import Tooltip from Bootstrap
import { Tooltip } from "bootstrap";
export default {
  data() {
    return {
      isChatboxVisible: false,
      message: "",
      number: null
    };
  },
  methods: {
    toggleChatbox() {
      this.isChatboxVisible = !this.isChatboxVisible;
    },
    sendMessage() {
      if (this.message.trim() !== "") {
        // Handle user message logic here
        console.log("User message:", this.message);
        this.message = "";
      }
    },
  },
  mounted() {
    // Initialize the tooltip manually
    const tooltipTriggerEl = this.$refs.tooltipButton;
    new Tooltip(tooltipTriggerEl);
  },
};
</script>

<style scoped>
body {
  margin-top: 20px;
}

.footer_area {
  position: relative;
  z-index: 1;
  overflow: hidden;
  webkit-box-shadow: 0 8px 48px 8px rgba(47, 91, 234, 0.175);
  box-shadow: 0 8px 48px 8px rgba(47, 91, 234, 0.175);
  padding: 60px;
  background-color: #000000;
  color: white;
}

.footer_area .row {
  margin-left: -25px;
  margin-right: -25px;
}

.footer_area .row .col,
.footer_area .row .col-1,
.footer_area .row .col-10,
.footer_area .row .col-11,
.footer_area .row .col-12,
.footer_area .row .col-2,
.footer_area .row .col-3,
.footer_area .row .col-4,
.footer_area .row .col-5,
.footer_area .row .col-6,
.footer_area .row .col-7,
.footer_area .row .col-8,
.footer_area .row .col-9,
.footer_area .row .col-auto,
.footer_area .row .col-lg,
.footer_area .row .col-lg-1,
.footer_area .row .col-lg-10,
.footer_area .row .col-lg-11,
.footer_area .row .col-lg-12,
.footer_area .row .col-lg-2,
.footer_area .row .col-lg-3,
.footer_area .row .col-lg-4,
.footer_area .row .col-lg-5,
.footer_area .row .col-lg-6,
.footer_area .row .col-lg-7,
.footer_area .row .col-lg-8,
.footer_area .row .col-lg-9,
.footer_area .row .col-lg-auto,
.footer_area .row .col-md,
.footer_area .row .col-md-1,
.footer_area .row .col-md-10,
.footer_area .row .col-md-11,
.footer_area .row .col-md-12,
.footer_area .row .col-md-2,
.footer_area .row .col-md-3,
.footer_area .row .col-md-4,
.footer_area .row .col-md-5,
.footer_area .row .col-md-6,
.footer_area .row .col-md-7,
.footer_area .row .col-md-8,
.footer_area .row .col-md-9,
.footer_area .row .col-md-auto,
.footer_area .row .col-sm,
.footer_area .row .col-sm-1,
.footer_area .row .col-sm-10,
.footer_area .row .col-sm-11,
.footer_area .row .col-sm-12,
.footer_area .row .col-sm-2,
.footer_area .row .col-sm-3,
.footer_area .row .col-sm-4,
.footer_area .row .col-sm-5,
.footer_area .row .col-sm-6,
.footer_area .row .col-sm-7,
.footer_area .row .col-sm-8,
.footer_area .row .col-sm-9,
.footer_area .row .col-sm-auto,
.footer_area .row .col-xl,
.footer_area .row .col-xl-1,
.footer_area .row .col-xl-10,
.footer_area .row .col-xl-11,
.footer_area .row .col-xl-12,
.footer_area .row .col-xl-2,
.footer_area .row .col-xl-3,
.footer_area .row .col-xl-4,
.footer_area .row .col-xl-5,
.footer_area .row .col-xl-6,
.footer_area .row .col-xl-7,
.footer_area .row .col-xl-8,
.footer_area .row .col-xl-9,
.footer_area .row .col-xl-auto {
  padding-right: 25px;
  padding-left: 25px;
}

.single-footer-widget {
  position: relative;
  z-index: 1;
}

.single-footer-widget .copywrite-text a {
  color: #747794;
  font-size: 1rem;
}

.single-footer-widget .copywrite-text a:hover,
.single-footer-widget .copywrite-text a:focus {
  color: #3f43fd;
}

.single-footer-widget .widget-title {
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}

.single-footer-widget .footer_menu li a {
  color: #747794;
  margin-bottom: 1rem;
  display: block;
  font-size: 1rem;
  text-decoration: none;
}

.single-footer-widget .footer_menu li a:hover,
.single-footer-widget .footer_menu li a:focus {
  color: #3f43fd;
}

.single-footer-widget .footer_menu li:last-child a {
  margin-bottom: 0;
}

.footer_social_area {
  position: relative;
  z-index: 1;
}

.footer_social_area a {
  border-radius: 50%;
  height: 40px;
  text-align: center;
  width: 40px;
  display: inline-block;
  background-color: #f5f5ff;
  line-height: 40px;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-right: 10px;
}

.footer_social_area a i {
  line-height: 36px;
}

.footer_social_area a:hover {

  background-color: blue;
  color: #fff;
  transition: 0.5s;

}

@-webkit-keyframes bi-cycle {
  0% {
    left: 0;
  }

  100% {
    left: 100%;
  }
}

@keyframes bi-cycle {
  0% {
    left: 0;
  }

  100% {
    left: 100%;
  }
}

ol li,
ul li {
  list-style: none;
}

ol,
ul {
  margin: 0;
  padding: 0;
}

/* ---chat css--- */

/* Floating Support Icon */
.support-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #28a745;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;
}
.support-icon:hover {
  transform: scale(1.1);
}

/* Chatbox */
.chatbox {
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 350px;
  z-index: 1000;
  border-radius: 10px;
  overflow: hidden;
}

/* Chatbox Body */
.chatbox-body {
  max-height: 300px;
  overflow-y: auto;
}

/* Custom Message Bubbles */
.user-message {
  align-self: flex-start;
}

.response-message {
  align-self: flex-end;
}
</style>
